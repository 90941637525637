import { track } from "./utils";

// Observe no-results
const noResultsCallback = function (mutationsList, observer) {
  for (const mutation of mutationsList) {
    if (mutation.type === "childList") {
      // Check added nodes
      mutation.addedNodes.forEach((node) => {
        if (
          node.nodeType === Node.ELEMENT_NODE &&
          node.classList.contains("yxt-AlternativeVerticals") &&
          node.classList.contains("yxt-AlternativeVerticals--notShowingResults")
        ) {
          track({ cbpgFunction: "searchresults-noresults"}, true);
        }
      });
    }
  }
};

const noResultsObserver = new MutationObserver(noResultsCallback);
noResultsObserver.observe(document.body, { childList: true, subtree: true });

// Observe results
const resultsCallback = function (mutationsList, observer) {
  for (const mutation of mutationsList) {
    if (mutation.type === "childList") {
      // Check added nodes
      mutation.addedNodes.forEach((node) => {
        if (
          node.nodeType === Node.ELEMENT_NODE &&
          node.classList.contains("yxt-Results") &&
          node.classList.contains("yxt-Results--locations")
        ) {
          track({ cbpgFunction: "searchresults" }, true);
        }
      });
    }
  }
};

const resultsObserver = new MutationObserver(resultsCallback);
resultsObserver.observe(document.body, { childList: true, subtree: true });
